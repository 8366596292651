import { Button, Card, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EMAIL_PATTERN } from '@/constants';
import { useSession } from '@/hooks';

import './login.css';

const Login = () => {
  const { t } = useTranslation();
  const { createSession, deleteSession } = useSession();

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm({ defaultValues: { email: '', password: '' }, mode: 'onBlur' });

  const { field: email, fieldState: emailState } = useController({
    control,
    name: 'email',
    rules: { pattern: EMAIL_PATTERN, required: true },
  });

  const { field: password, fieldState: passwordState } = useController({
    control,
    name: 'password',
    rules: { required: true },
  });

  // Just to keep TS calm, we can just do handleSubmit(createSession.mutate) and it works
  const onClick = handleSubmit((data) => createSession.mutate({ ...data }));

  useEffect(() => {
    deleteSession.mutate();
  }, []);

  return (
    <Card className="login" variant="elevation">
      <form onSubmit={onClick}>
        <TextField
          error={emailState.invalid}
          helperText={emailState.invalid && t('login.emailError')}
          label={t('login.email')}
          variant="outlined"
          {...email}
        />
        <TextField
          error={passwordState.invalid}
          label={t('login.password')}
          type="password"
          variant="outlined"
          {...password}
        />
        <Button
          color="primary"
          disabled={!isValid || createSession.isPending}
          type="submit"
          variant="contained"
          onClick={onClick}
        >
          {t('login.login')}
        </Button>
      </form>
    </Card>
  );
};

export default Login;
