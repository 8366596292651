import { ArrowBack } from '@mui/icons-material';
import { Button, IconButton, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useInterval, useResponse } from '@/hooks';

import Section from './Section';

import type { Test } from '@/types/test';
import type { FC } from 'react';

import Loading from '@/components/Loading';

type SubmitProps = {
  readonly?: boolean;
  testData?: Test;
};

const Submit: FC<SubmitProps> = ({
  readonly = false,
  testData = {} as Test,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { responseId = '' } = useParams();

  const { useGetResponse, answerResponse } = useResponse();
  const { data: response, isLoading: responseLoading } =
    useGetResponse(responseId);

  const now = new Date().getTime();
  const canStart =
    (!!response &&
      !response.finished &&
      new Date(response.startsAt).getTime() <= now) ||
    readonly;

  const { control, getValues, handleSubmit, formState, setValue } = useForm();

  const onBack = () => navigate('/tests');

  const onSubmit = handleSubmit((answers) => {
    if (readonly) return;
    answerResponse.mutate({ answers, finished: true, id: responseId });
    navigate('/responses');
  });

  const autosave = () => {
    if (readonly) return;
    answerResponse.mutate({ answers: getValues(), id: responseId });
  };

  useInterval(autosave, 10000, !readonly && canStart);

  useEffect(() => {
    if (responseLoading || !response) return;

    response.answers.forEach(({ questionId, value }) => {
      setValue(questionId, value);
    });
  }, [response, responseLoading]);

  useEffect(() => {
    if (responseLoading || !response) return;
    if (response.finished) navigate('/responses');
  }, [response, responseLoading]);

  let test = testData;

  if (!readonly) {
    if (responseLoading || !response) return <Loading />;
    test = response.test;
  }

  if (!canStart)
    return <Typography variant="h3">{t('responses.notStarted')}</Typography>;

  return (
    <form className="response-form" onSubmit={onSubmit}>
      {readonly && (
        <IconButton color="secondary" onClick={onBack}>
          <ArrowBack />
          {t('common.back')}
        </IconButton>
      )}
      <Typography variant="h3">{test.name}</Typography>
      <Typography variant="subtitle1">{test.description}</Typography>
      {test.sections.map((section) => (
        <Section
          key={`response-submit-section-${section.id}`}
          control={control}
          section={section}
        />
      ))}
      <Button
        color="primary"
        disabled={!formState.isValid || answerResponse.isPending || readonly}
        type="submit"
        variant="contained"
        onClick={onSubmit}
      >
        {t('common.send')}
      </Button>
    </form>
  );
};

export default Submit;
