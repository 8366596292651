export enum UserRoles {
  admin = 'admin',
  student = 'student',
  teacher = 'teacher',
}

export type User = {
  email: string;
  firstName: string;
  fullName: string;
  id: string;
  isAdmin: boolean;
  isStudent: boolean;
  isTeacher: boolean;
  maternalLastName?: string;
  paternalLastName?: string;
  role: UserRoles;
  shouldResetPassword: boolean;
  students?: User[];
  teacher?: User;
};

export type Reset = {
  id: string;
  password: string;
  passwordConfirmation: string;
};
