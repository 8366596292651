import { ArrowBack } from '@mui/icons-material';
import { Button, IconButton, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useCurrentUser, useTest } from '@/hooks';

import SectionBuilder from './SectionBuilder';

import type { TestForm, Test as TestType } from '@/types/test';

import Field from '@/components/Field';
import Loading from '@/components/Loading';
import Submit from '@/components/Responses/Submit';

const mapTestData = (data: TestForm) => {
  const mapped = {
    sections: data.sections,
    test: {
      description: data.description,
      name: data.name,
    },
  };

  Object.keys(data).forEach((key) => {
    const [field, index] = key.split('-');
    const record = data[key];
    if (!index) return;

    // Type is complex, simpler to just ignore it
    // @ts-ignore
    mapped.sections[index][`${field}s`] = record;
  });

  return mapped as Partial<TestType>;
};

const Test = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { testId } = useParams();

  const { currentUser } = useCurrentUser();
  const { useGetTest, createTest, updateTest } = useTest();
  const { data: test, isLoading: testLoading } = useGetTest(testId);

  const isAdmin = currentUser?.isAdmin;
  const path = 'tests.test';

  const {
    control,
    formState: { isValid },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      description: '',
      name: '',
    } as TestForm,
    mode: 'onBlur',
  });

  const onClick = handleSubmit((data) => {
    if (testId) updateTest.mutate({ ...mapTestData(data), id: test?.id ?? '' });
    else createTest.mutate({ ...mapTestData(data) });
  });

  const onBack = () => navigate('/tests');

  useEffect(() => {
    if (testLoading || !test) return;

    setValue('name', test.name);
    setValue('description', test.description);
  }, [test, testLoading]);

  if ((testLoading || !test) && !!testId) return <Loading />;

  if (!isAdmin && test) return <Submit testData={test} readonly />;

  return (
    <>
      <IconButton color="primary" sx={{ marginLeft: '2rem' }} onClick={onBack}>
        <ArrowBack />
        <Typography variant="h6">&nbsp;{t('common.back')}</Typography>
      </IconButton>
      <form className="test" onSubmit={onClick}>
        <Field control={control} name="name" path={path} />
        <Field control={control} name="description" path={path} />
        <SectionBuilder control={control} test={test} />
        <Button
          color="primary"
          disabled={!isValid || updateTest.isPending || createTest.isPending}
          type="submit"
          variant="contained"
          onClick={onClick}
        >
          {t(`common.${testId ? 'update' : 'create'}`)}
        </Button>
      </form>
    </>
  );
};

export default Test;
