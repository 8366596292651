import Cookies from 'js-cookie';

import { JWT_EXPIRATION } from '@/constants';

import client from './client';

import type { SessionUser } from '@/types/session';
import type { User } from '@/types/user';

export const login = async (user: SessionUser): Promise<User> => {
  const response = await client.post('/login', { user });
  const jwt = response.headers.authorization.replace('Bearer ', '');
  Cookies.set('jwt', jwt, { expires: JWT_EXPIRATION });
  return response.data;
};

export const logout = async (): Promise<unknown> => {
  await client.delete('/logout');
  Cookies.remove('jwt');
  return {};
};

export default { login, logout };
