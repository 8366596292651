import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  all,
  answer,
  create,
  destroy,
  get,
  update,
  upload,
} from '@/api/response';

const useResponse = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const useGetResponses = (page = 1, search = '') =>
    useQuery({
      queryFn: () => all(page, search),
      queryKey: ['responses', page, search],
    });

  const useGetResponse = (id?: string) =>
    useQuery({
      enabled: !!id,
      queryFn: () => get(id ?? ''),
      queryKey: ['responses', id],
    });

  const createResponse = useMutation({
    mutationFn: create,
    onError: () => toast.error(t('common.error')),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['responses'] });
      navigate('/responses');
    },
  });

  const uploadResponse = useMutation({
    mutationFn: upload,
    onError: () => toast.error(t('common.error')),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['response', data.id] });
      queryClient.invalidateQueries({ queryKey: ['responses'] });
    },
  });

  const answerResponse = useMutation({
    mutationFn: answer,
    onError: () => toast.error(t('common.error')),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['response', data.id] });
      queryClient.invalidateQueries({ queryKey: ['responses'] });
    },
  });

  const updateResponse = useMutation({
    mutationFn: update,
    onError: () => toast.error(t('common.error')),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['response', data.id] });
      queryClient.invalidateQueries({ queryKey: ['responses'] });
      navigate('/responses');
    },
  });

  const deleteResponse = useMutation({
    mutationFn: destroy,
    onError: () => toast.error(t('common.error')),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['responses'] });
    },
  });

  return {
    answerResponse,
    createResponse,
    deleteResponse,
    updateResponse,
    uploadResponse,
    useGetResponse,
    useGetResponses,
  };
};

export default useResponse;
