import { createContext, useMemo, useState } from 'react';

import { useInterceptors } from '@/hooks';

import type { FC, PropsWithChildren } from 'react';

import Loading from '@/components/Loading';
import Navigation from '@/components/Navigation';

export const AppContext = createContext<{}>({});

// WARNING: API calls made here need to wait on interceptorsSetup to be true
const AppProvider: FC<PropsWithChildren> = ({ children }) => {
  // To not change every render and keep TS happy
  const value = useMemo(() => ({}), []);
  const [interceptorsSetup, setInterceptorsSetup] = useState(false);
  useInterceptors(setInterceptorsSetup);

  return (
    <AppContext.Provider value={value}>
      <Navigation>{interceptorsSetup ? children : <Loading />}</Navigation>
    </AppContext.Provider>
  );
};

export default AppProvider;
