import { Delete, PersonAdd, Upload } from '@mui/icons-material';
import {
  Button,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TABLE_HIDDEN } from '@/constants';
import { useUser } from '@/hooks';

import type { ChangeEvent, MouseEvent } from 'react';

import Search from '@/components/Search';

const Users = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { useGetUsers, bulkCreateUser, deleteUser } = useUser();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { data: users } = useGetUsers(page, search);

  const path = 'users.list';
  const onNew = () => navigate('new');
  const onRow = (id: string) => () => navigate(`${id}`);

  const onFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) toast.error(t('common.fileError'));
    else bulkCreateUser.mutate(file);
  };

  const onChangePage = (_event: ChangeEvent<unknown>, value: number) =>
    setPage(value);

  const onDelete = (id: string) => (event: MouseEvent) => {
    event.stopPropagation();
    deleteUser.mutate(id);
  };

  const onChangeSearch = (value: string) => {
    setSearch(value);
    setPage(1);
  };

  return (
    <>
      <div className="users-list">
        <div className="header">
          <Search search={search} setSearch={onChangeSearch} />
          <IconButton className="upload" color="secondary" component="label">
            <Upload />
            <input type="file" hidden onChange={onFile} />
          </IconButton>
          <Button
            className="new"
            color="success"
            variant="contained"
            onClick={onNew}
          >
            <PersonAdd />
            <Typography variant="body1">&nbsp;{t('common.new')}</Typography>
          </Button>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t(`${path}.fullName`)}</TableCell>
                <TableCell>{t(`${path}.email`)}</TableCell>
                <TableCell sx={TABLE_HIDDEN}>{t(`${path}.teacher`)}</TableCell>
                <TableCell sx={TABLE_HIDDEN}>{t(`${path}.role`)}</TableCell>
                <TableCell sx={TABLE_HIDDEN}>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.data.map(({ id, fullName, email, role, teacher }) => (
                <TableRow key={id} onClick={onRow(id)}>
                  <TableCell>{fullName}</TableCell>
                  <TableCell>{email}</TableCell>
                  <TableCell sx={TABLE_HIDDEN}>{teacher?.fullName}</TableCell>
                  <TableCell sx={TABLE_HIDDEN}>
                    {t(`users.roles.${role}`)}
                  </TableCell>
                  <TableCell sx={TABLE_HIDDEN}>
                    <IconButton color="error" onClick={onDelete(id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Pagination
        className="pagination"
        count={users?.pages ?? 0}
        page={page}
        size="large"
        onChange={onChangePage}
      />
    </>
  );
};

export default Users;
