import { CircularProgress, InputLabel, MenuItem, Select } from '@mui/material';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUser } from '@/hooks';

import type { FC } from 'react';
import type { Control } from 'react-hook-form';

type SelectUsersProps = {
  control: Control;
  disabled: boolean;
};

const SelectUsers: FC<SelectUsersProps> = ({ control, disabled }) => {
  const path = 'responses.response';
  const { t } = useTranslation();
  const { useGetUsers } = useUser();

  // This will eventually need an infinite scroll
  const { data: users, isLoading: usersLoading } = useGetUsers(1, '', 1000);

  const { field } = useController({
    control,
    name: 'userIds',
    rules: { required: true },
  });

  return (
    <>
      <InputLabel id="user-select-label">{t(`${path}.user`)}</InputLabel>
      <Select
        disabled={disabled}
        label={t(`${path}.user`)}
        labelId="user-select-label"
        multiple
        {...field}
      >
        {usersLoading ? (
          <MenuItem>
            <CircularProgress />
          </MenuItem>
        ) : (
          users?.data.map(({ id, fullName }) => (
            <MenuItem key={`user-select-${id}`} value={id}>
              {fullName}
            </MenuItem>
          ))
        )}
      </Select>
    </>
  );
};

export default SelectUsers;
