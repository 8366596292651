import { ArrowBack } from '@mui/icons-material';
import { Button, Card, IconButton, TextField, Typography } from '@mui/material';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser, useUser } from '@/hooks';

import Loading from '@/components/Loading';

const Password = () => {
  const path = 'users.password';
  const { currentUser, currentUserLoading } = useCurrentUser();
  const { resetPassword } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    defaultValues: { password: '', passwordConfirmation: '' },
    mode: 'onBlur',
  });

  const { field: password, fieldState: passwordState } = useController({
    control,
    name: 'password',
    rules: { required: true },
  });

  const { field: passwordConfirmation, fieldState: passwordConfirmationState } =
    useController({
      control,
      name: 'passwordConfirmation',
      rules: { required: true, validate: (value) => password.value === value },
    });

  const onClick = handleSubmit((data) => {
    resetPassword.mutate({ ...data, id: currentUser?.id ?? '' });
  });

  const back = () => navigate(`/users/${currentUser?.id}`);

  if (currentUserLoading) {
    return <Loading />;
  }

  return (
    <>
      <IconButton
        aria-label="back"
        className="back"
        color="secondary"
        sx={{ marginLeft: '2rem' }}
        onClick={back}
      >
        <ArrowBack />
        &nbsp;
        <Typography variant="h6">{t('common.back')}</Typography>
      </IconButton>
      <Card className="password" variant="elevation">
        <form onSubmit={onClick}>
          <TextField
            error={passwordState.invalid}
            label={t(`${path}.password`)}
            type="password"
            variant="outlined"
            {...password}
          />
          <TextField
            error={passwordConfirmationState.invalid}
            helperText={
              passwordConfirmationState.invalid && t(`${path}.invalid`)
            }
            label={t(`${path}.confirmation`)}
            type="password"
            variant="outlined"
            {...passwordConfirmation}
          />
          <Button
            color="primary"
            disabled={!isValid || resetPassword.isPending}
            type="submit"
            variant="contained"
            onClick={onClick}
          >
            {t(`${path}.button`)}
          </Button>
        </form>
      </Card>
    </>
  );
};
export default Password;
