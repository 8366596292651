import { Checkbox, FormControlLabel } from '@mui/material';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { FC } from 'react';
import type { Control } from 'react-hook-form';

type ShouldResetCheckboxProps = {
  control: Control;
};

const ShouldResetCheckbox: FC<ShouldResetCheckboxProps> = ({ control }) => {
  const { t } = useTranslation();
  const path = 'users.user';
  const { field } = useController({
    control,
    name: 'shouldResetPassword',
  });

  return (
    <FormControlLabel
      control={<Checkbox checked={field.value} {...field} />}
      label={t(`${path}.shouldResetPassword`)}
    />
  );
};

export default ShouldResetCheckbox;
