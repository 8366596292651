import {
  Cancel,
  Check,
  Delete,
  PlaylistAdd,
  Upload,
} from '@mui/icons-material';
import {
  Button,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TABLE_HIDDEN } from '@/constants';
import { useCurrentUser, useResponse } from '@/hooks';

import type { ChangeEvent, MouseEvent } from 'react';

import Loading from '@/components/Loading';
import Search from '@/components/Search';

const Responses = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { useGetResponses, uploadResponse, deleteResponse } = useResponse();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const { currentUser, currentUserLoading } = useCurrentUser();
  const { data: responses } = useGetResponses(page, search);

  const path = 'responses.list';
  const onNew = () => navigate('new');
  const { isAdmin, isTeacher } = currentUser ?? {};

  const onRow = (id: string, finished: boolean) => () => {
    let route = id;
    if (finished) route += '/review';
    else if (!isTeacher) route += '/submit';
    navigate(route);
  };

  const onChangePage = (_event: ChangeEvent<unknown>, value: number) =>
    setPage(value);

  const onDelete = (id: string) => (event: MouseEvent) => {
    event.stopPropagation();
    deleteResponse.mutate(id);
  };

  const onChangeSearch = (value: string) => {
    setSearch(value);
    setPage(1);
  };

  const onUpload = (event: MouseEvent) => event.stopPropagation();

  const onFile = (id: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) toast.error(t('common.fileError'));
    else uploadResponse.mutate({ file, id });
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString('es')} ${date.toLocaleTimeString('es', { hour: 'numeric', minute: 'numeric' })}`;
  };

  if (currentUserLoading) return <Loading />;

  return (
    <>
      <div className="responses-list">
        <div className="header">
          <Search search={search} setSearch={onChangeSearch} />
          {isTeacher && (
            <Button
              className="new"
              color="success"
              variant="contained"
              onClick={onNew}
            >
              <PlaylistAdd />
              <Typography variant="body1">&nbsp;{t('common.new')}</Typography>
            </Button>
          )}
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t(`${path}.email`)}</TableCell>
                <TableCell>{t(`${path}.testName`)}</TableCell>
                <TableCell>{t(`${path}.finished`)}</TableCell>
                <TableCell sx={TABLE_HIDDEN}>{t(`${path}.result`)}</TableCell>
                <TableCell sx={TABLE_HIDDEN}>{t(`${path}.startsAt`)}</TableCell>
                {isTeacher && <TableCell sx={TABLE_HIDDEN}>&nbsp;</TableCell>}
                {isAdmin && <TableCell sx={TABLE_HIDDEN}>&nbsp;</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {responses?.data.map(
                ({
                  id,
                  finished,
                  percentage,
                  result,
                  startsAt,
                  user,
                  test,
                }) => (
                  <TableRow key={id} onClick={onRow(id, finished)}>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{test.name}</TableCell>
                    <TableCell>{percentage}%</TableCell>
                    <TableCell sx={TABLE_HIDDEN}>
                      {result ? <Check /> : <Cancel />}
                    </TableCell>
                    <TableCell sx={TABLE_HIDDEN}>
                      {formatDate(startsAt)}
                    </TableCell>
                    {isAdmin && (
                      <TableCell sx={TABLE_HIDDEN}>
                        <IconButton
                          color="secondary"
                          component="label"
                          onClick={onUpload}
                        >
                          <Upload />
                          <input type="file" hidden onChange={onFile(id)} />
                        </IconButton>
                      </TableCell>
                    )}
                    {isTeacher && (
                      <TableCell sx={TABLE_HIDDEN}>
                        <IconButton color="error" onClick={onDelete(id)}>
                          <Delete />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Pagination
        className="pagination"
        count={responses?.pages ?? 0}
        page={page}
        size="large"
        onChange={onChangePage}
      />
    </>
  );
};

export default Responses;
