import { Search as SearchIcon } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '@/hooks';

import type { TextFieldProps } from '@mui/material';
import type { FC } from 'react';

type SearchProps = TextFieldProps & {
  search: string;
  setSearch: (value: string) => void;
  timeout?: number;
};

export const Search: FC<SearchProps> = ({
  search,
  setSearch,
  timeout = 250,
  ...rest
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(search);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  useDebounce(value, setSearch, timeout);

  return (
    <TextField
      {...rest}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      label={`${t('common.search')}...`}
      onChange={onChange}
    />
  );
};

export default Search;
