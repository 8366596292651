import { useEffect } from 'react';

const useInterval = (
  callback: () => void,
  milliSeconds = 250,
  enabled = false,
) => {
  useEffect(() => {
    if (enabled) {
      const interval = setInterval(() => {
        callback();
      }, milliSeconds);

      return () => clearInterval(interval);
    }
    return () => {};
  }, [milliSeconds, enabled]);
};

export default useInterval;
