import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import Cookies from 'js-cookie';

import { UUID_PATTERN } from '@/constants';

const options = {
  preservedKeys: (input: string) => UUID_PATTERN.test(input),
};

const client = applyCaseMiddleware(
  axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    transformRequest: (data, headers) => {
      const jwt = Cookies.get('jwt');
      const isJson = headers['Content-Type'] === 'application/json';
      if (jwt) headers.Authorization = `Bearer ${jwt}`; // eslint-disable-line no-param-reassign -- need to set headers directly here
      return isJson ? JSON.stringify(data) : data;
    },
  }),
  options,
);

export default client;
