import { Logout } from '@mui/icons-material';
import { AppBar, Button, Container, IconButton, Toolbar } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { MOBILE_DISPLAY, MOBILE_HIDDEN } from '@/constants';
import { usePages } from '@/hooks';

import type { Page } from '@/types/common';
import type { FC, PropsWithChildren } from 'react';

import './navigation.css';

const Navigation: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { pages, loading } = usePages();
  const onLogo = () => navigate('/');
  const onLogout = () => navigate('/login');

  if (pathname === '/login' || loading) return children;

  return (
    <>
      <AppBar className="navigation" color="transparent" position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Button className="logo" onClick={onLogo}>
              <img alt={t('common.app')} src="./logo.png" />
            </Button>

            {pages.map(({ name, icon, onClick }: Page) => (
              <Fragment key={name}>
                <Button sx={MOBILE_HIDDEN} onClick={onClick}>
                  {name}
                </Button>
                <IconButton
                  color="primary"
                  sx={MOBILE_DISPLAY}
                  onClick={onClick}
                >
                  | {icon} |
                </IconButton>
              </Fragment>
            ))}

            <Button className="logout" sx={MOBILE_HIDDEN} onClick={onLogout}>
              {t('navigation.logout')}
            </Button>
            <IconButton
              className="logout"
              color="primary"
              sx={MOBILE_DISPLAY}
              onClick={onLogout}
            >
              <Logout />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
      {children}
    </>
  );
};

export default Navigation;
