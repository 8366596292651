import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { all, create, destroy, get, update } from '@/api/test';

const useTest = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const useGetTests = (page = 1, name = '', limit?: number) =>
    useQuery({
      queryFn: () => all(page, name, limit),
      queryKey: ['tests', page, name],
    });

  const useGetTest = (id?: string) =>
    useQuery({
      queryFn: () => get(id ?? ''),
      queryKey: ['test', id],
    });

  const createTest = useMutation({
    mutationFn: create,
    onError: () => toast.error(t('common.error')),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tests'] });
      navigate('/tests');
    },
  });

  const updateTest = useMutation({
    mutationFn: update,
    onError: () => toast.error(t('common.error')),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['test', data.id] });
      queryClient.invalidateQueries({ queryKey: ['tests'] });
      navigate('/tests');
    },
  });

  const deleteTest = useMutation({
    mutationFn: destroy,
    onError: () => toast.error(t('common.error')),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tests'] });
    },
  });

  return { createTest, deleteTest, updateTest, useGetTest, useGetTests };
};

export default useTest;
