import { ArrowBack } from '@mui/icons-material';
import { Button, IconButton, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useResponse } from '@/hooks';

import DatePicker from './DatePicker';
import SelectTest from './SelectTest';
import SelectUsers from './SelectUsers';

import type { ResponseForm } from '@/types/response';

import Loading from '@/components/Loading';

const Response = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { responseId } = useParams();

  const { useGetResponse, createResponse, updateResponse } = useResponse();
  const { data: response, isLoading: responseLoading } =
    useGetResponse(responseId);

  const {
    control,
    formState: { isValid },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      startsAt: '',
      testId: '',
      userIds: [],
    } as Partial<ResponseForm>,
    mode: 'onBlur',
  });

  const onClick = handleSubmit((data) => {
    const startsAt = new Date(`${data.startsAt}:00`).toISOString();
    const payload = { ...(data as ResponseForm), startsAt };
    const id = response?.id ?? '';

    if (responseId) updateResponse.mutate({ ...payload, id });
    else createResponse.mutate(payload);
  });

  const onBack = () => navigate('/responses');

  useEffect(() => {
    if (responseLoading || !response) return;

    const startsAt = new Date(response.startsAt).toISOString().slice(0, 16);
    setValue('testId', response.testId);
    setValue('startsAt', startsAt);
    setValue('userIds', [response.userId]);
  }, [response, responseLoading]);

  if ((responseLoading || !response) && !!responseId) return <Loading />;

  return (
    <>
      <IconButton color="primary" sx={{ marginLeft: '2rem' }} onClick={onBack}>
        <ArrowBack />
        <Typography variant="h6">&nbsp;{t('common.back')}</Typography>
      </IconButton>
      <form className="users" onSubmit={onClick}>
        <SelectTest control={control} />
        <SelectUsers control={control} disabled={!!responseId} />
        <DatePicker control={control} />
        <Button
          color="primary"
          disabled={
            !isValid || updateResponse.isPending || createResponse.isPending
          }
          type="submit"
          variant="contained"
          onClick={onClick}
        >
          {t(`common.${response ? 'update' : 'create'}`)}
        </Button>
      </form>
    </>
  );
};

export default Response;
