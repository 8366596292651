import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { me } from '@/api/user';

const useCurrentUser = (enabled = true) => {
  const { data: currentUser, isLoading: currentUserLoading } = useQuery({
    enabled,
    queryFn: me,
    queryKey: ['me'],
  });

  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !currentUserLoading &&
      currentUser?.shouldResetPassword &&
      pathname !== 'password'
    ) {
      navigate('/users/password');
    }
  }, [currentUser, currentUserLoading, pathname]);

  return { currentUser, currentUserLoading };
};

export default useCurrentUser;
