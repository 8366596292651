import { Group, ListAlt, ManageAccounts, Quiz } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import useCurrentUser from './useCurrentUser';

const usePages = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { currentUser, currentUserLoading } = useCurrentUser(
    !pathname.includes('login'),
  );

  if (!currentUser) {
    return { loading: currentUserLoading, pages: [] };
  }

  const pages = [
    {
      icon: <ListAlt />,
      name: t('navigation.tests'),
      onClick: () => navigate('/tests'),
    },
    {
      icon: <Group />,
      name: currentUser.isAdmin
        ? t('navigation.users')
        : t('navigation.students'),
      onClick: () => navigate('/users'),
    },
    {
      icon: <Quiz />,
      name: t('navigation.responses'),
      onClick: () => navigate('/responses'),
    },
    {
      icon: <ManageAccounts />,
      name: t('navigation.profile'),
      onClick: () => navigate(`/users/${currentUser.id}`),
    },
  ];

  if (!currentUser.isTeacher) {
    pages.shift();
    pages.shift();
  }

  return { loading: currentUserLoading, pages };
};

export default usePages;
