import { CircularProgress } from '@mui/material';

import './loading.css';

const Loading = () => (
  <div className="loading">
    <CircularProgress size={100} />
  </div>
);

export default Loading;
