import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useResponse } from '@/hooks';

import Loading from '@/components/Loading';

const Review = () => {
  const { t } = useTranslation();
  const { responseId } = useParams();
  const { useGetResponse } = useResponse();

  const { data: response, isLoading: responseLoading } =
    useGetResponse(responseId);

  if (responseLoading || !response) return <Loading />;
  if (!response.result)
    return (
      <Typography className="pending" variant="h3">
        {t('responses.pending')}
      </Typography>
    );

  return <embed src={response.result} />;
};

export default Review;
