import { Button } from '@mui/material';
import { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import QuestionsBuilder from './QuestionsBuilder';
import TagsBuilder from './TagsBuilder';

import type { Section, Test } from '@/types/test';
import type { FC } from 'react';
import type { Control } from 'react-hook-form';

import Field from '@/components/Field';

type TagsBuilderProps = {
  control: Control;
  test: Test | undefined;
};

const SectionBuilder: FC<TagsBuilderProps> = ({ control, test }) => {
  const { t } = useTranslation();
  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: 'sections',
  });
  const path = 'tests.test';

  const getName = (index: number) => `sections.${index}`;

  const label = t(`${path}.section`);
  const description = t(`${path}.description`);

  const onAdd = () => {
    append({ description: '', questions: [], tags: [], title: '' });
  };

  const onDelete = (index: number) => () => remove(index);

  useEffect(() => {
    test?.sections.forEach((section, index) => {
      remove(index);
      insert(index, { ...section });
    });
  }, []);

  return (
    <div className="sections-test">
      <Button onClick={onAdd}>
        {t('common.add')}&nbsp;{label}
      </Button>
      {fields.map((field, index) => (
        <div key={`section-${index}`} className="section">
          <Button color="error" onClick={onDelete(index)}>
            {t('common.delete')}&nbsp;{label}
          </Button>
          <Field
            control={control}
            label={label}
            name={`${getName(index)}.title`}
            fullWidth
          />
          <Field
            control={control}
            label={description}
            name={`${getName(index)}.description`}
            fullWidth
          />
          <TagsBuilder
            control={control}
            index={index}
            section={field as Section}
          />
          <QuestionsBuilder
            control={control}
            index={index}
            section={field as Section}
          />
        </div>
      ))}
    </div>
  );
};

export default SectionBuilder;
