import { Delete } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { Section } from '@/types/test';
import type { FC } from 'react';
import type { Control } from 'react-hook-form';

import Field from '@/components/Field';

type TagsBuilderProps = {
  control: Control;
  index: number;
  section: Section;
};

const TagsBuilder: FC<TagsBuilderProps> = ({ control, index, section }) => {
  const { t } = useTranslation();
  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: `tag-${index}`,
  });
  const path = 'tests.test';

  const getName = (tagIndex: number) => `tag-${index}.${tagIndex}`;

  const onAdd = () => {
    append({ label: '', title: '' });
  };

  const onDelete = (tagIndex: number) => () => remove(tagIndex);

  const label = t(`${path}.tagLabel`);
  const titleLabel = t(`${path}.tag`);

  useEffect(() => {
    section.tags.forEach((tag, tagIndex) => {
      remove(tagIndex);
      insert(tagIndex, { ...tag });
    });
  }, []);

  return (
    <div className="tags">
      <Button onClick={onAdd}>
        {t('common.add')}&nbsp;{titleLabel}
      </Button>
      {fields.map((_, tagIndex) => (
        <div key={`tags-${index}-${tagIndex}`} className="tag">
          <Field
            control={control}
            label={titleLabel}
            name={`${getName(tagIndex)}.title`}
            fullWidth
          />
          <Field
            control={control}
            label={label}
            name={`${getName(tagIndex)}.label`}
          />
          <Field
            control={control}
            defaultValue={`${tagIndex}`}
            label={t(`${path}.value`)}
            name={`${getName(tagIndex)}.value`}
          />
          <IconButton color="error" onClick={onDelete(tagIndex)}>
            <Delete />
          </IconButton>
        </div>
      ))}
    </div>
  );
};

export default TagsBuilder;
