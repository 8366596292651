import { Add, Delete } from '@mui/icons-material';
import {
  Button,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TABLE_HIDDEN } from '@/constants';
import { useCurrentUser, useTest } from '@/hooks';

import type { ChangeEvent, MouseEvent } from 'react';

import Loading from '@/components/Loading';
import Search from '@/components/Search';

const Tests = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { useGetTests, deleteTest } = useTest();

  const { currentUser, currentUserLoading } = useCurrentUser();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { data: tests } = useGetTests(page, search);

  const path = 'tests.list';
  const onNew = () => navigate('new');
  const onRow = (id: string) => () => navigate(`${id}`);

  const onChangePage = (_event: ChangeEvent<unknown>, value: number) =>
    setPage(value);

  const onDelete = (id: string) => (event: MouseEvent) => {
    event.stopPropagation();
    deleteTest.mutate(id);
  };

  const onChangeSearch = (value: string) => {
    setSearch(value);
    setPage(1);
  };

  const isAdmin = currentUser?.isAdmin;

  if (currentUserLoading) return <Loading />;

  return (
    <>
      <div className="tests-list">
        <div className="header">
          <Search search={search} setSearch={onChangeSearch} />
          {isAdmin && (
            <Button
              className="new"
              color="success"
              variant="contained"
              onClick={onNew}
            >
              <Add />
              <Typography variant="body1">&nbsp;{t('common.new')}</Typography>
            </Button>
          )}
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t(`${path}.name`)}</TableCell>
                <TableCell>{t(`${path}.description`)}</TableCell>
                {isAdmin && <TableCell>&nbsp;</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {tests?.data.map(({ id, name, description }) => (
                <TableRow key={id} onClick={onRow(id)}>
                  <TableCell>{name}</TableCell>
                  <TableCell>{description}</TableCell>
                  {isAdmin && (
                    <TableCell sx={TABLE_HIDDEN}>
                      <IconButton color="error" onClick={onDelete(id)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Pagination
        className="pagination"
        count={tests?.pages ?? 0}
        page={page}
        size="large"
        onChange={onChangePage}
      />
    </>
  );
};

export default Tests;
