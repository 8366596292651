import client from './client';

import type { Paginated } from '@/types/common';
import type { Reset, User } from '@/types/user';

export const me = async (): Promise<User> =>
  (await client.get('/users/me')).data;

export const get = async (id: string): Promise<User> =>
  (await client.get(`/users/${id}`)).data;

export const all = async (
  page = 1,
  email = '',
  limit?: number,
): Promise<Paginated<User>> =>
  (await client.get('/users', { params: { email, limit, page } })).data;

export const bulk = async (file: File): Promise<User[]> => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const formData = new FormData();

  formData.append('file', file);
  const response = await client.post('/users/bulk', formData, {
    headers,
  });

  return response.data;
};

export const create = async (user: Partial<User>): Promise<User> =>
  (await client.post('/users', { user })).data;

export const reset = async ({
  id,
  password,
  passwordConfirmation,
}: Reset): Promise<User> =>
  (await client.patch(`/users/${id}/reset`, { password, passwordConfirmation }))
    .data;

export const update = async (user: Partial<User>): Promise<User> =>
  (await client.patch(`/users/${user.id}`, { user })).data;

export const destroy = async (id: string): Promise<{}> =>
  (await client.delete(`/users/${id}`)).data;
