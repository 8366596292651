import client from './client';

import type { Paginated } from '@/types/common';
import type { Test } from '@/types/test';

export const get = async (id: string): Promise<Test> =>
  (await client.get(`/tests/${id}`)).data;

export const all = async (
  page = 1,
  name = '',
  limit?: number,
): Promise<Paginated<Test>> =>
  (await client.get('/tests', { params: { limit, name, page } })).data;

export const create = async (test: Partial<Test>): Promise<Test> =>
  (await client.post('/tests', { ...test })).data;

export const update = async (test: Partial<Test>): Promise<Test> =>
  (await client.patch(`/tests/${test.id}`, { ...test })).data;

export const destroy = async (id: string): Promise<{}> =>
  (await client.delete(`/tests/${id}`)).data;
