import client from './client';

import type { Paginated } from '@/types/common';
import type {
  Response,
  ResponseForm,
  SubmitForm,
  Upload,
} from '@/types/response';

export const get = async (id: string): Promise<Response> =>
  (await client.get(`/responses/${id}`)).data;

export const all = async (
  page = 1,
  search = '',
  limit?: number,
): Promise<Paginated<Response>> =>
  (await client.get('/responses', { params: { limit, page, search } })).data;

export const create = async ({
  testId,
  startsAt,
  userIds,
}: ResponseForm): Promise<Response> =>
  (await client.post('/responses', { response: { startsAt, testId }, userIds }))
    .data;

export const upload = async ({ id, file }: Upload): Promise<Response> => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const formData = new FormData();

  formData.append('file', file);
  const response = await client.post(`/responses/${id}/upload`, formData, {
    headers,
  });

  return response.data;
};

export const answer = async ({
  id,
  answers,
  finished,
}: SubmitForm): Promise<Response> =>
  (await client.patch(`/responses/${id}/answer`, { answers, finished })).data;

export const update = async (response: Partial<Response>): Promise<Response> =>
  (await client.patch(`/responses/${response.id}`, { response })).data;

export const destroy = async (id: string): Promise<{}> =>
  (await client.delete(`/responses/${id}`)).data;
