import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useController } from 'react-hook-form';

import type { Tag } from '@/types/test';
import type { FC } from 'react';
import type { Control } from 'react-hook-form';

type TagsProps = {
  control: Control;
  id: string;
  tags: Tag[];
};

const Tags: FC<TagsProps> = ({ control, id, tags }) => {
  const { field } = useController({
    control,
    defaultValue: '',
    name: id,
    rules: { required: true },
  });

  return (
    <RadioGroup className="choices" row {...field}>
      {tags.map(({ label, value }) => (
        <FormControlLabel
          key={`response-${id}-${label}`}
          control={<Radio />}
          label={label}
          value={value}
        />
      ))}
    </RadioGroup>
  );
};

export default Tags;
