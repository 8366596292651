import { TextField } from '@mui/material';
import { useController } from 'react-hook-form';

import type { FC } from 'react';
import type { Control} from 'react-hook-form';

type DatePickerProps = {
  control: Control;
};

const DatePicker: FC<DatePickerProps> = ({ control }) => {
  const { field } = useController({
    control,
    name: 'startsAt',
    rules: { required: true },
  });

  return <TextField lang="es-MX" type="datetime-local" {...field} />;
};

export default DatePicker;
