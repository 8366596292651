import { CircularProgress, InputLabel, MenuItem, Select } from '@mui/material';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useTest } from '@/hooks';

import type { FC } from 'react';
import type { Control } from 'react-hook-form';

type SelectTestProps = {
  control: Control;
};

const SelectTest: FC<SelectTestProps> = ({ control }) => {
  const { t } = useTranslation();
  const path = 'responses.response';
  const { useGetTests } = useTest();

  // This will eventually need an infinite scroll
  const { data: tests, isLoading: testsLoading } = useGetTests(1, '', 1000);

  const { field } = useController({
    control,
    name: 'testId',
    rules: { required: true },
  });

  return (
    <>
      <InputLabel id="test-select-label">{t(`${path}.test`)}</InputLabel>
      <Select label={t(`${path}.test`)} labelId="test-select-label" {...field}>
        {testsLoading ? (
          <MenuItem>
            <CircularProgress />
          </MenuItem>
        ) : (
          tests?.data.map(({ id, name }) => (
            <MenuItem key={`test-select-${id}`} value={id}>
              {name}
            </MenuItem>
          ))
        )}
      </Select>
    </>
  );
};

export default SelectTest;
