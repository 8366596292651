import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser } from '@/hooks';

import Loading from '@/components/Loading';

const Root = () => {
  const navigate = useNavigate();
  const { currentUser, currentUserLoading } = useCurrentUser();

  useEffect(() => {
    if (currentUserLoading || !currentUser) return;
    if (currentUser.isAdmin) navigate('/tests');
    else navigate('/responses');
  }, [currentUser, currentUserLoading]);

  if (currentUserLoading || !currentUser) return <Loading />;

  return null;
};

export default Root;
