import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FORBIDDEN, NOT_FOUND, UNAUTHORIZED } from '@/constants';
import { getPath } from '@/utils';

import type { AxiosError } from 'axios';
import type { Dispatch, SetStateAction } from 'react';

import client from '@/api/client';

const useInterceptors = (
  setInterceptorsSetup: Dispatch<SetStateAction<boolean>>,
) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    const errorInterceptor = client.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        const { status = '' } = error.response ?? {};
        if (status === NOT_FOUND || status === FORBIDDEN) {
          // Do not provide additional info of a record existing but not being allowed
          toast.error(t('common.notFoundError'));
          navigate('/');
        }

        if (status === UNAUTHORIZED) {
          // Using this because useLocation was returning the previous path
          const path = getPath();
          if (!path.includes('login'))
            navigate(`/login${path ? `?redirect=${path}` : ''}`);
        }

        return Promise.reject(error);
      },
    );
    setInterceptorsSetup(true);
    return () => {
      client.interceptors.response.eject(errorInterceptor);
    };
  }, []);
};

export default useInterceptors;
