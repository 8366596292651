import { ArrowBack } from '@mui/icons-material';
import { Button, IconButton, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useCurrentUser, useUser } from '@/hooks';

import RoleSelect from './RoleSelect';
import ShouldResetCheckbox from './ShouldResetCheckbox';

import type { User as UserType } from '@/types/user';

import Field from '@/components/Field';
import Loading from '@/components/Loading';
import { UserRoles } from '@/types/user';

const User = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userId } = useParams();

  const { currentUser } = useCurrentUser();
  const { useGetUser, createUser, updateUser } = useUser();
  const { data: user, isLoading: userLoading } = useGetUser(userId);

  const path = 'users.user';
  const isCurrentUser = userId === currentUser?.id;

  const {
    control,
    formState: { isValid },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
      firstName: '',
      maternalLastName: '',
      paternalLastName: '',
      role: UserRoles.student,
      shouldResetPassword: false,
    } as Partial<UserType>,
    mode: 'onBlur',
  });

  const onClick = handleSubmit((data) => {
    if (userId) updateUser.mutate({ ...data, id: user?.id ?? '' });
    else createUser.mutate({ ...data });
  });

  const onResetPassword = () => navigate('/users/password');
  const onBack = () => navigate('/users');

  useEffect(() => {
    if (userLoading || !user) return;

    setValue('email', user.email);
    setValue('firstName', user.firstName);
    setValue('paternalLastName', user.paternalLastName);
    setValue('maternalLastName', user.maternalLastName);
    setValue('role', user.role);
    setValue('shouldResetPassword', user.shouldResetPassword);
  }, [user, userLoading]);

  if ((userLoading || !user) && !!userId) return <Loading />;

  return (
    <>
      {currentUser?.isTeacher && (
        <IconButton
          color="primary"
          sx={{ marginLeft: '2rem' }}
          onClick={onBack}
        >
          <ArrowBack />
          <Typography variant="h6">&nbsp;{t('common.back')}</Typography>
        </IconButton>
      )}
      <form className="users" onSubmit={onClick}>
        <Field control={control} name="email" path={path} />
        <Field control={control} name="firstName" path={path} />
        <Field control={control} name="paternalLastName" path={path} />
        <Field control={control} name="maternalLastName" path={path} />
        <RoleSelect control={control} />
        {!isCurrentUser && !!userId && (
          <ShouldResetCheckbox control={control} />
        )}
        <Button
          color="primary"
          disabled={!isValid || updateUser.isPending || createUser.isPending}
          type="submit"
          variant="contained"
          onClick={onClick}
        >
          {t(`common.${user ? 'update' : 'create'}`)}
        </Button>
        {isCurrentUser && (
          <Button color="warning" variant="contained" onClick={onResetPassword}>
            {t(`${path}.shouldResetPassword`)}
          </Button>
        )}
      </form>
    </>
  );
};

export default User;
