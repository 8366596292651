import { TextField } from '@mui/material';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { FC } from 'react';
import type { Control } from 'react-hook-form';

type FieldProps = {
  control: Control;
  defaultValue?: string;
  fullWidth?: boolean;
  label?: string;
  name: string;
  path?: string;
  required?: boolean;
};

const Field: FC<FieldProps> = ({
  control,
  defaultValue,
  name,
  path,
  ...rest
}) => {
  const { t } = useTranslation();

  const helperText = path ? t(`${path}.required`) : t('common.required');

  const { fieldState, field } = useController({
    control,
    defaultValue,
    name,
    rules: { required: true },
  });

  return (
    <TextField
      error={fieldState.invalid}
      helperText={fieldState.invalid && helperText}
      label={t(`${path}.${name}`)}
      variant="outlined"
      {...rest}
      {...field}
    />
  );
};

export default Field;
