import { MenuItem, Select } from '@mui/material';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from '@/hooks';

import type { FC } from 'react';
import type { Control } from 'react-hook-form';

import { UserRoles } from '@/types/user';


type RoleSelectProps = {
  control: Control;
};

const RoleSelect: FC<RoleSelectProps> = ({ control }) => {
  const path = 'users.user';
  const { t } = useTranslation();
  const { currentUser, currentUserLoading } = useCurrentUser();

  const { field } = useController({
    control,
    name: 'role',
    rules: { required: true },
  });

  const roles = [UserRoles.admin, UserRoles.teacher, UserRoles.student];

  if (currentUserLoading || !currentUser || !currentUser.isAdmin) return null;

  return (
    <Select label={t(`${path}.role`)} {...field}>
      {roles.map((role) => (
        <MenuItem key={`user-form-role-${role}`} value={role}>
          {t(`${path}.${role}`)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default RoleSelect;
