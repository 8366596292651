export const FIRST = 1;
export const JWT_EXPIRATION = 7;
export const EMAIL_PATTERN =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/u;
export const UUID_PATTERN =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/iu;
export const FIRST_SLASH_AFTER_PROTOCOL = 3;
export const NOT_FOUND = 404;
export const FORBIDDEN = 403;
export const UNAUTHORIZED = 401;
export const MOBILE_DISPLAY = { display: { sm: 'none' } };
export const MOBILE_HIDDEN = { display: { sm: 'block', xs: 'none' } };
export const TABLE_HIDDEN = {
  display: { sm: 'table-cell', xs: 'none' },
};
