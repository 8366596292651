/* eslint-disable import/extensions -- Without extension build fails */
import en from './en.json';
import es from './es.json';
/* eslint-enable import/extensions */

export default {
  fallbackLng: 'en',
  interpolation: { escapeValue: false },
  lng: 'es',
  resources: { en, es },
};
