import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

import type { Tag } from '@/types/test';
import type { FC } from 'react';


type TagDescriptionProps = {
  tags: Tag[];
};

const TagDescription: FC<TagDescriptionProps> = ({ tags }) => (
  <TableContainer component={Paper} sx={{ width: '15rem' }}>
    <Table size="small">
      <TableBody>
        {tags.map(({ label, title }) => (
          <TableRow key={`tag-description-${title}`}>
            <TableCell>{label}</TableCell>
            <TableCell>{title}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default TagDescription;
