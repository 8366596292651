import { Route, Routes } from 'react-router-dom';

import Login from '@/components/Login';
import { Response, Responses, Review, Submit } from '@/components/Responses';
import Root from '@/components/Root';
import { Test, Tests } from '@/components/Tests';
import { Password, User, Users } from '@/components/Users';

const AppRoutes = () => (
  <Routes>
    <Route element={<Root />} index />
    <Route element={<Login />} path="/login" />
    <Route path="/users">
      <Route element={<Users />} index />
      <Route element={<User />} path="new" />
      <Route element={<User />} path=":userId" />
      <Route element={<Password />} path="password" />
    </Route>
    <Route path="/tests">
      <Route element={<Tests />} index />
      <Route element={<Test />} path="new" />
      <Route element={<Test />} path=":testId" />
    </Route>
    <Route path="/responses">
      <Route element={<Responses />} index />
      <Route element={<Response />} path="new" />
      <Route element={<Response />} path=":responseId" />
      <Route element={<Submit />} path=":responseId/submit" />
      <Route element={<Review />} path=":responseId/review" />
    </Route>
  </Routes>
);

export default AppRoutes;
