import { Typography } from '@mui/material';

import Question from './Question';
import TagDescription from './TagDescription';

import type { Section as SectionType } from '@/types/test';
import type { FC } from 'react';
import type { Control } from 'react-hook-form';


type SectionProps = {
  control: Control;
  section: SectionType;
};

const Section: FC<SectionProps> = ({ control, section }) => (
  <div className="section">
    <div className="header">
      <div>
        <Typography variant="h5">{section.title}</Typography>
        <Typography variant="body1">{section.description}</Typography>
      </div>
      <TagDescription tags={section.tags} />
    </div>
    {section.questions.map((question) => (
      <Question
        key={`section-questions-${question.id}`}
        control={control}
        tags={section.tags}
        {...question}
      />
    ))}
  </div>
);

export default Section;
