import { Typography } from '@mui/material';

import Tags from './Tags';

import type { Tag } from '@/types/test';
import type { FC } from 'react';
import type { Control } from 'react-hook-form';

type QuestionProps = {
  control: Control;
  id: string;
  tags: Tag[];
  value: string;
};

const Question: FC<QuestionProps> = ({ value, ...rest }) => (
  <div className="question">
    <Typography variant="body2">{value}</Typography>
    <Tags {...rest} />
  </div>
);

export default Question;
