import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const Toast = () => (
  <ToastContainer
    autoClose={3000}
    draggable={false}
    position="bottom-center"
    rtl={false}
    closeOnClick
    pauseOnFocusLoss
  />
);

export default Toast;
