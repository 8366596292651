import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { login, logout } from '@/api/session';

const useSession = () => {
  const { t } = useTranslation();
  const [search] = useSearchParams();
  const to = search.get('redirect') || '/';
  const navigate = useNavigate();

  const createSession = useMutation({
    mutationFn: login,
    onError: () => {
      toast.error(t('login.failed'));
    },
    onSuccess: () => {
      navigate(to);
    },
  });

  const deleteSession = useMutation({
    mutationFn: logout,
  });

  return { createSession, deleteSession };
};

export default useSession;
