import { Delete } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { Section } from '@/types/test';
import type { FC} from 'react';
import type { Control} from 'react-hook-form';

import Field from '@/components/Field';

type QuestionsBuilderProps = {
  control: Control;
  index: number;
  section: Section;
};

const QuestionsBuilder: FC<QuestionsBuilderProps> = ({
  control,
  index,
  section,
}) => {
  const { t } = useTranslation();
  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: `question-${index}`,
  });
  const path = 'tests.test';

  const getName = (questionIndex: number) =>
    `question-${index}.${questionIndex}.value`;

  const onAdd = () => {
    append({ value: '' });
  };

  const onDelete = (questionIndex: number) => () => remove(questionIndex);

  const label = t(`${path}.question`);

  useEffect(() => {
    section.questions.forEach((question, questionIndex) => {
      remove(questionIndex);
      insert(questionIndex, { ...question });
    });
  }, []);

  return (
    <div className="questions">
      <Button onClick={onAdd}>
        {t('common.add')}&nbsp;{label}
      </Button>
      {fields.map((_, questionIndex) => (
        <div
          key={`questions-${section.id}-${questionIndex}`}
          className="question"
        >
          <Field
            control={control}
            label={label}
            name={getName(questionIndex)}
            fullWidth
          />
          <IconButton color="error" onClick={onDelete(questionIndex)}>
            <Delete />
          </IconButton>
        </div>
      ))}
    </div>
  );
};

export default QuestionsBuilder;
