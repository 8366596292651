import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import type { ErrorData } from '@/types/common';
import type { AxiosError } from 'axios';

import { all, bulk, create, destroy, get, reset, update } from '@/api/user';

const useUser = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const useGetUsers = (page = 1, email = '', limit?: number) =>
    useQuery({
      queryFn: () => all(page, email, limit),
      queryKey: ['users', page, email],
    });

  const useGetUser = (id?: string) =>
    useQuery({
      enabled: !!id,
      // Not actually needed, but TS does not know this does not run if id is not present
      queryFn: () => get(id ?? ''),
      queryKey: ['user', id],
    });

  const bulkCreateUser = useMutation({
    mutationFn: bulk,
    onError: (error: AxiosError) => {
      if (error.response?.data) {
        const data = error.response.data as ErrorData;
        if (data.errors.email) toast.error(t('users.user.emailError'));
        else toast.error(t('common.error'));
      } else {
        toast.error(t('common.error'));
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });

  const createUser = useMutation({
    mutationFn: create,
    onError: (error: AxiosError) => {
      if (error.response?.data) {
        const data = error.response.data as ErrorData;
        if (data.errors.email) toast.error(t('users.user.emailError'));
        else toast.error(t('common.error'));
      } else {
        toast.error(t('common.error'));
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      navigate('/users');
    },
  });

  const resetPassword = useMutation({
    mutationFn: reset,
    onError: () => toast.error(t('common.error')),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['me'] });
      navigate('/');
    },
  });

  const updateUser = useMutation({
    mutationFn: update,
    onError: () => toast.error(t('common.error')),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['me'] });
      queryClient.invalidateQueries({ queryKey: ['user', data.id] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      navigate('/');
    },
  });

  const deleteUser = useMutation({
    mutationFn: destroy,
    onError: () => toast.error(t('common.error')),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['users'] }),
  });

  return {
    bulkCreateUser,
    createUser,
    deleteUser,
    resetPassword,
    updateUser,
    useGetUser,
    useGetUsers,
  };
};

export default useUser;
